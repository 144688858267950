import { React, useEffect, useState, useCallback } from "react";

import {
  Button,
  List,
  ListItem,
  ListIcon,
  Flex,
  Text,
  createStandaloneToast,
  useColorModeValue,
  Icon,
  SimpleGrid,
  Link,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import Information from "components/admin/Information";
import { useParams } from "react-router-dom";
import { customerGetOrder, markFileAsRead } from "apis/order";
import FormContainer from "components/admin/FormContainer";
import moment from "moment";
import {
  MomentFormatDateOnly,
  MomentFormatWithTimeZone,
  OrderStatusMsg,
  OrderStatusIcon,
  OrderStatusColor,
  OrderStatus,
} from "utils/Constants";
import ActionDialog from "components/admin/ActionDialogBtn";
import { MdAttachFile, MdCheck } from "react-icons/md";
import { updateOrderStatus } from "apis/order";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import Card from "components/card/Card.js";
import AuthImage from "components/auth/AuthImage";
import AuthLink from "components/auth/AuthLink";

export default function OrderView() {
  const toast = createStandaloneToast();
  const { id } = useParams();
  const backUrl = "/order/history";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [orderData, setData] = useState(null);
  const cardShadow = useColorModeValue(
    "0px 20px 40px rgba(112, 144, 176, 0.2)",
    "unset"
  );
  const uploadedFilesCardBg = useColorModeValue("white", "navy.700");

  const canMakeChanges = useCallback(() => {
    if (orderData == null) {
      return false;
    }
    let twoDaysBeforeDelivery = moment(orderData.deliveryDateTime)
      .subtract(2, "days")
      .toDate();

    return (
      (orderData.status == OrderStatus.Pending ||
        orderData.status == OrderStatus.AmendmentRequested || 
        orderData.status == OrderStatus.Confirmed) &&
      twoDaysBeforeDelivery > new Date()
    );
  }, [orderData]);

  function changeStatus(id, status, message) {
    updateOrderStatus(id, status).then((res) => {
      getData();
      toast({
        title: "Successful",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    });
  }

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    customerGetOrder(id)
      .then((res) => {
        const order = res.data;
        const [hour, min, sec] = order.deliveryTime.split(":");
        order.deliveryDateTime = moment(order.deliveryDate)
          .add({
            hours: hour,
            minutes: min,
            seconds: sec,
          })
          .toDate();
        order.requiredDateTime = moment(order.deliveryDateTime).format(
          MomentFormatWithTimeZone
        );
        order.orderedDateTimeFormatted = moment(order.orderedDateTime).format(
          MomentFormatWithTimeZone
        );
        setData(order);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateFileReadStatus(file) {
    if (!file.isRead) {
      markFileAsRead(id, file.name)
        .then(() => getData())
        .catch((err) => console.log(err));
    }
  }

  return (
    orderData && (
      <FormContainer backUrl={backUrl}>
        <Flex
          align="end"
          justify="end"
          flexDirection={{ base: "column", md: "row" }}
          gap={2}
          my="12px"
        >
          {canMakeChanges() && (
            <NavLink to={"/order/edit/" + id}>
              <Button colorScheme="red" rounded="md">
                Request to Update Detail
              </Button>
            </NavLink>
          )}
          {canMakeChanges() && orderData.status != OrderStatus.Cancelled && (
            <ActionDialog
              btnText="Cancel Order"
              dialogHeader="Cancel Order"
              dialogText="Are you sure you want to cancel this order?"
              confirmBtnText="Confirm"
              colorScheme="red"
              onConfirm={() =>
                changeStatus(
                  orderData.id,
                  OrderStatus.Cancelled,
                  "This order has been cancelled."
                )
              }
            />
          )}
        </Flex>
        <Text
          color={textColor}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Order Details
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap="20px">
          <Information
            boxShadow={cardShadow}
            title="Product"
            value={orderData.productName}
          />
          <Information
            boxShadow={cardShadow}
            title="Ordered Quantity"
            value={orderData.quantity}
          />
          <Information
            boxShadow={cardShadow}
            title="Ordered Time"
            value={orderData.orderedDateTimeFormatted}
          />
          <Information
            boxShadow={cardShadow}
            title="Delivery Time"
            value={orderData.requiredDateTime}
          />

          <Information
            boxShadow={cardShadow}
            title="Order Person"
            value={orderData.orderPerson}
          />

          <Information
            boxShadow={cardShadow}
            title="Purchase Order"
            value={orderData.purchaseOrder || "N/A"}
          />
          <Information
            boxShadow={cardShadow}
            title="Invoice Number"
            value={orderData.invoiceNumber || "N/A"}
          />
          <Information
            boxShadow={cardShadow}
            title="Status"
            value={
              <Flex align="center">
                <Icon
                  w="24px"
                  h="24px"
                  me="5px"
                  color={OrderStatusColor[orderData.status]}
                  as={OrderStatusIcon[orderData.status]}
                />
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {OrderStatusMsg[orderData.status]}
                </Text>
              </Flex>
            }
          />
        </SimpleGrid>
        <Text
          color={textColor}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Patient's Details
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap="20px">
          <Information
            boxShadow={cardShadow}
            title="Name"
            value={orderData.patientDetails.name}
          />
          <Information
            boxShadow={cardShadow}
            title="Weight"
            value={orderData.patientDetails.weightInKG}
          />
          <Information
            boxShadow={cardShadow}
            title="Date of Birth"
            value={moment(orderData.patientDetails.dateOfBirth).format(
              MomentFormatDateOnly
            )}
          />
          <Information
            boxShadow={cardShadow}
            title="Doctor Name"
            value={orderData.patientDetails.doctorName}
          />
        </SimpleGrid>
        <Text
          color={textColor}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Additional Information
        </Text>
        <Information
          boxShadow={cardShadow}
          value={orderData.additionalInformation || "None"}
        />
        <Text
          color={textColor}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Files
        </Text>

        <Card mb="10px" bg={uploadedFilesCardBg}>
          <Text fontWeight="500" color="gray.400" fontSize="sm">
            Uploaded files
          </Text>
          <List spacing={3}>
            {orderData.files.map((file) => (
              <ListItem>
                <AuthLink
                  href={file.name}
                  icon={MdAttachFile}
                  color="blue.400"
                  isExternal
                  isList
                >
                  {file.originalName}
                </AuthLink>
              </ListItem>
            ))}
          </List>
        </Card>

        <Card bg={uploadedFilesCardBg}>
          <Text fontWeight="500" color="gray.400" fontSize="sm">
            Quality release documents
          </Text>
          <List spacing={3}>
            {orderData.sharedFiles.map((file, i) => (
              <Tooltip
                key={i}
                hasArrow
                placement="bottom-start"
                label={
                  file.isReadOn
                    ? `Read on ${moment(file.isReadOn).format(
                        MomentFormatWithTimeZone
                      )}`
                    : null
                }
              >
                <ListItem onClick={() => updateFileReadStatus(file)}>
                  {file.isRead && <ListIcon as={MdCheck} color={"green.400"} />}
                  <AuthLink
                    href={file.name}
                    icon={MdAttachFile}
                    color={file.isRead ? "green.400" : "blue.400"}
                    isExternal
                    isList
                  >
                    {file.originalName}
                  </AuthLink>
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </Card>

        {orderData?.deliveredImageUrl && (
          <Information
            mt="10px"
            boxShadow={cardShadow}
            title="Delivery Image"
            value={
              <AuthImage
                maxH="300px"
                alt="Delivery image"
                src={orderData.deliveredImageUrl}
              />
            }
          />
        )}
      </FormContainer>
    )
  );
}
