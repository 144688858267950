import { Box, createStandaloneToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { columnsDataComplex } from "views/admin/timeslot/components/columnsData";
import {
  getProductionTimeSlots,
  disableProductionTimeSlot,
} from "apis/productionTimeSlot";
import ItemsTable from "components/admin/ItemsTable";
import FilterBar from "views/admin/timeslot/components/filterBar";

export default function TimeSlot() {
  useEffect(() => {
    refreshTimeSlots();
  }, []);

  const [data, setData] = useState([]);
  const toast = createStandaloneToast();
  const [unfilteredData, setUnfilteredData] = useState([]);

  function prepareData(data) {
    const preparedData = data.map((timeSlot) => {
      return timeSlot;
    });
    setData(preparedData);
  }


  function refreshTimeSlots() {
    getProductionTimeSlots()
      .then((res) => {
        setUnfilteredData(res.data);
        prepareData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function DisableTimeSlot(id, isDisabled) {
    disableProductionTimeSlot(id, !isDisabled).then(res => {
      refreshTimeSlots();
      toast({
        title: 'Successful',
        description: `Time Slot ${isDisabled ? "enabled" : "disabled"}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={prepareData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="timeslot/view"
        createUrl="timeslot/create"
        disableCallback={DisableTimeSlot}
        disableBtnText="Disable"
        disableDialogHeader="Disable"
        disableDialogText="Are you sure you want to disable "
        disableConfirmBtnText="Disable"
      />
    </Box>
  );
}
