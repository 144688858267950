import axios from "axios";
import api from "./api";
import { createStandaloneToast } from "@chakra-ui/react";

const API_URL = `${process.env.REACT_APP_API_URL}`;
const toast = createStandaloneToast();

const upload = async (route, form) => {
    try {
        const res = await axios.post(`${API_URL}/${route}`, form, {
            headers: {
                "Content-Type": "multipart/form-data",
                "token": localStorage.getItem("token"),
            },
        });
        return res.data;

    } catch (error) {
        toast({
            title: 'An error occurred.',
            description: error.response.data.message ?? error.response.data.title,
            status: 'error',
            duration: 5000,
            isClosable: true,
        })
        console.log("files upload", error);
        return Promise.reject(error.response.data);
    }
}

export const filesUpload = async (form) => {
    return await upload("files-upload", form);
};

export const imageUpload = async (form) => {
    return await upload("image-upload", form);
};

export const mixedFilesUpload = async (form) => {
    return await upload("mixed-files-upload", form);
};

export const deleteFiles = async (fileNames) => {
    const res = await api.post(`${API_URL}/files-delete`, fileNames);
    return res;
};

export const deleteImage = async (imageName) => {
    const res = await api.delete(`${API_URL}/image-delete/${imageName}`);
    return res;
};

export const getFile = async (fileName) => {
    try {
        const res = await axios.get(`${API_URL}/file/${fileName}`,  {
            headers: {
                // "Content-Type": "multipart/form-data",
                "token": localStorage.getItem("token"),
            },
            responseType: 'blob',
        });
        return res.data;
    } catch (error) {
        return Promise.reject(error.response.data);
    }
}


export const getImage = async (imageName) => {
    try {
        const res = await axios.get(`${API_URL}/image/${imageName}`,  {
            headers: {
                // "Content-Type": "multipart/form-data",
                "token": localStorage.getItem("token"),
            },
            responseType: 'blob',
        });
        return res.data;
    } catch (error) {
        return Promise.reject(error.response.data);
    }
}

export const checkFileExistence = async (fileName) => {
    const res = await api.get(`${API_URL}/file-exist/${fileName}`);
    return res;
};