import {
    Text,
    useColorModeValue,
    FormErrorMessage,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
} from "@chakra-ui/react";

import React, { useState, useEffect} from "react";

import FormContainer from "components/admin/FormContainer";

import { getOrganisation, createOrganisation, updateOrganisation } from "apis/organisation";
import { useParams, useHistory } from "react-router-dom"
import { Field } from 'formik';
import { validateEmpty } from "utils/Constants";

export default function OrganisationForm() {
    const [currentData, setCurrentData] = useState(null);
    const backUrl = "/admin/organisations";
    const { id } = useParams();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    
    const history = useHistory();

    const initValues = {
        name: "",
        contactNumber: "",
        email: "",
        address: "",
    };

    function refreshData(){
        if (id) {
            getOrganisation(id).then(res => {
                setCurrentData(res.data)
            }).catch(err => {
                console.log(err);
            })
        }
    }
    
    useEffect(() => {
        refreshData()
    }, []);

    return ((!id || currentData) && (
        <FormContainer
            backUrl={backUrl}
            initialValues={currentData || initValues}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                if (!id) {
                    createOrganisation(values).then(res => {
                        actions.setSubmitting(false)
                        history.push(backUrl)
                    }).catch(err => {
                        console.log(err);
                    })
                } else {
                    updateOrganisation(id, values).then(res => {
                        actions.setSubmitting(false)
                        history.push(backUrl)
                    }).catch(err => {
                        console.log(err);
                    })
                }
            }}
        >
            <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%' mb="24px">
                Orgainsation Information
            </Text>

            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
                <Field name="name" validate={validateEmpty}>
                    {({ field, form }) => {
                        return (
                            <FormControl id="name" isInvalid={form.errors.name && form.touched.name}>
                                <FormLabel htmlFor="name">Name</FormLabel>
                                <Input placeholder="Hospital Name" {...field} />
                                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                            </FormControl>
                        )
                    }}
                </Field>
                <Field name="contactNumber" validate={validateEmpty}>
                    {({ field, form }) => (
                        <FormControl id="contactNumber" isInvalid={form.errors.contactNumber && form.touched.contactNumber}>
                            <FormLabel htmlFor="contactNumber">Contact Number</FormLabel>
                            <Input placeholder="0412345678" {...field} />
                            <FormErrorMessage>{form.errors.contactNumber}</FormErrorMessage>
                        </FormControl>
                    )}
                </Field>
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
                <Field name="email" validate={validateEmpty}>
                    {({ field, form }) => (
                        <FormControl id="email" isInvalid={form.errors.email && form.touched.email}>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <Input placeholder="user@example.com" {...field} />
                            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                        </FormControl>
                    )}
                </Field>
                <Field name="address" validate={validateEmpty}>
                    {({ field, form }) => (
                        <FormControl id="address" isInvalid={form.errors.address && form.touched.address}>
                            <FormLabel htmlFor="address">Address</FormLabel>
                            <Input placeholder="123 Example Street, City, State, ZIP Code" {...field} />
                            <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                        </FormControl>
                    )}
                </Field>
            </SimpleGrid>
        </FormContainer>
    ));

} 